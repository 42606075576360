import React, { useEffect, useContext } from "react";
import Layout from "../components/layout";
import { AuthContext, AuthProvider} from "../components/AuthProvider";
import ErrorComponent from '../components/Form/ErrorComponent';
import Logo from '../components/Logo'
import {Link} from 'gatsby'
import MagicallyLogin from '../components/MagicallyLogin'

function MagicLinkLogin({location}) {

  const { loginMagicLink, user } = useContext(AuthContext);

  const urlParams= location && location.search

  let urlParamsSplit  = ""
  let email = ""
  let magicCode = ""

  if(urlParams) {

     urlParamsSplit =  urlParams.split('&')

    email = urlParamsSplit[0] && urlParamsSplit[0].split('email=').pop()
  
     magicCode = urlParamsSplit[1] && urlParamsSplit[1].split('magicCode=').pop()
   
  
  }


 
  return (
  <Layout location={location} hideHeader={true} hideFooter={true}>
    <div className="py-12">
      <title>CalPERS</title>
      <div className="w-80">
                <Link
                  to={"/account/dashboard"}
                  className="block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                >
                  <Logo fill="#00659e" includeTitle={true} />
                </Link>
              </div>
     

    
      </div>
    <MagicallyLogin email={email} magicCode={magicCode}/>
    </Layout>
  );
}

export default MagicLinkLogin;
