import React, {useState, useContext, useEffect} from 'react';
import { Link } from "gatsby";
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { AuthContext, AuthProvider} from "./AuthProvider";
import ErrorComponent from './Form/ErrorComponent';
import { Spinner } from './Atoms/Spinner';

function MagicallyLogin({email, magicCode}) {
    const { user, login, loginMagicLink, isUserLoggedIn, loginErrors, requestPinCode, spinner, loggingInStatus, loginState, changeLoginState, } = useContext(AuthContext);
   
    


    
  useEffect(() => {
    loginMagicLink(email, magicCode)
    }, [email, magicCode])

    return (
 
      <div>
      <div className="max-w-7xl mx-auto px-4 md:px-6 text-center py-40">
       <p className="text-gray-700 font-semibold"><Spinner className="mx-auto" size="6" /></p>
      </div>
    </div>
 
    );
}

export default MagicallyLogin;